<template>
  <div class="crumbs" >
    <el-breadcrumb  separator-class="el-icon-arrow-right" class="crumbs_a" :class="isMobile ? 'isMobile' : ''">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(ite, index) in item"
        :key="index"
        :to="{ path: ite.link,params:{} }"
        >{{ ite.title }}</el-breadcrumb-item
      >
      <!-- <el-breadcrumb-item :to="{ path: '/health' }"
        >享健康专区</el-breadcrumb-item
      > -->
    </el-breadcrumb>
  </div>
</template>

<script>
import common from '@/utils/common'
export default {
  name: "crumbs",
  props: {
    item: {
      type: Array,
    },
  },
  data() {
    return {
      isMobile:common.isMobile(),
    }
  }
};
</script>

<style lang="less" scoped>
::v-deep.el-breadcrumb {
  padding: 1.5rem 0;
  width: 80vw;
  margin: auto;
  font-size: px2rem(12, 375);
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #409eff !important;
    cursor: pointer;
  }
}
::v-deep.el-breadcrumb__item .el-breadcrumb__inner,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a:hover,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920)
}
.isMobile {
  line-height: 2;
  font-size: px2rem(5, 375);
}
</style>