export default {
    // 是否手机
    isMobile () {
        return  window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
    },
    
    isPad () {
        return  window.navigator.userAgent.match(
            /(pad|pod|iPod|iPad)/i
        );
    },
    
};