<template>
  <div class="vipZone">
    <crumbs :item="title" />
    <div class="wrap_box">
      <div class="box">
        <div class="banner">
          <img
            src="@/static/images/secondaryMenu/productsServices/banner_vip@2x.jpg"
            alt=""
          />
        </div>
        <div class="main">
<!--          <div @click="vip('AZ02501')" class="club">-->
          <!-- <div @click="jumpTo(`/productsServices/vipText/${'AZ02501'}`)" class="club"> -->
          <div class="club">
            <div class="pic">
              <img
                src="@/static/images/secondaryMenu/productsServices/bg_VIP_05@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="club">
            <div class="pic">
              <img
                src="@/static/images/secondaryMenu/productsServices/bg_VIP_01@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="club">
            <div class="pic">
              <img
                src="@/static/images/secondaryMenu/productsServices/bg_VIP_03@2x.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div @click="jumpTo(`/productsServices/vipText/${'AZ02502'}`)" class="club"> -->
          <div class="club">
            <div class="pic">
              <img
                src="@/static/images/secondaryMenu/productsServices/bg_VIP_02@2x.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div @click="jumpTo(`/productsServices/vipText/${'AZ02503'}`)" class="club"> -->
          
          <!-- <div @click="jumpTo(`/productsServices/vipText/${'AZ02503'}`)" class="club"> -->
          <div class="club">
            <div class="pic">
              <img
                src="@/static/images/secondaryMenu/productsServices/bg_VIP_04@2x.png"
                alt=""
              />
            </div>
          </div>
          <div style="text-align: justify;">
            <p>1、安联人寿提供的会员权益具体服务内容及细则以最新公布为准，安联人寿有权终止、 更换、调整及解释会员权益俱乐部提供的各项服务内容、会员标准及实施细则。请关注安联人寿官网上最新版《安联人寿会员权益俱乐部权益导览》。</p>
            <p>2、会员享受服务项目时与服务商发生纠纷的，均属会员个人与服务商之间的商业纠纷，安联人寿不承担任何法律责任，但会尽力协助进行沟通处理，维护会员的权益。</p>
            <p style="padding-bottom:3%">3、安联会员权益俱乐部所提供的会员权益服务项目不会以除服务以外的形式提供，且不作为保险合同的组成部分</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import crumbs from "@/components/crumbs.vue";
export default {
  name: "vipZone",
  data() {
    return {
      title: [
        {
          title: "产品服务",
          link: "",
        },
        {
          title: "其他服务",
          link: "",
        },
        {
          title: "会员权益俱乐部专区",
          link: "/productsServices/vipZone",
        },
      ],
    };
  },
  methods:{
    jumpTo(menu) {
      this.$router.push(menu);
    },
      // `/azContent/introduce/${"AZ00507"}`
  },
  components: { crumbs },
};
</script>

<style  lang="less" scoped>
.vipZone {
  .wrap_box {
    width: 100%;
    .box {
      width: 80%;
      margin: auto;
      .banner {
        width: 100%;
        //height: 28vw;
        margin: 5rem 0 7rem 0;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .main {
        width: 100%;
        // border: 1px solid;

        > .club {
          width: 100%;
          //height: 27vw;
          display: flex;
          margin: 6rem 0;
          //height: 52rem;
          cursor: pointer;

          .pic {
            //width: 55%;
            width: 100%;
            height: 100%;
            > img {
              width: 100%;
              height: 100%;
              cursor: default;
            }
          }
          .text {
            height: 100%;
            width: 45%;
            background-color: #dfeff2;
            padding: 5rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            > .vip {
              font-size: 4rem;
            }
            > pre {
              text-align: center;
              font-size: 2rem;
            }
            .btn {
              > span {
                background-color: #003781;
                padding: 1.5rem 5rem;
                font-size: 2rem;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>